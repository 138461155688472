.qcm-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
}

.qcm-wrapper .qcm-answer {
    width: 100%;
    
    
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    
}

/* During selection phase */
.qcm-wrapper .qcm-answer {
    cursor: pointer;
}

.qcm-wrapper .qcm-answer:hover {
    border-color: var(--amplify-colors-brand-primary-20)!important;
}

.qcm-wrapper .qcm-answer.checked {
    border-color: var(--amplify-colors-brand-primary-60)!important;
}

.qcm-wrapper .custom-checkbox {
    width: 24px;
    height: 24px;
    border: 2px solid #575a5e!important;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
}

.qcm-wrapper .custom-checkbox::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 4px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.qcm-wrapper .custom-checkbox.checked::before {
    background-color: var(--amplify-colors-brand-primary-60);
}

.qcm-wrapper .custom-checkbox.checked {
    border: 2px solid var(--amplify-colors-brand-primary-60);
}

/* Once validated */
.qcm-wrapper.correct .qcm-answer,
.qcm-wrapper.false .qcm-answer {
    cursor: default;
}

.qcm-wrapper.correct .qcm-answer {
    border-color: green!important;
}

.qcm-wrapper.false .qcm-answer {
    border-color: red!important;
}

.qcm-wrapper.correct .qcm-answer:hover{
    border-color: green!important;
}
.qcm-wrapper.false .qcm-answer:hover {
    border-color: red!important;
}

.qcm-wrapper .correction-icon {
    width:36px!important;
    margin-left: 24px;
    display: flex;
    justify-content: center;
}

.qcm-wrapper .custom-checkbox.disabled {
    cursor: default;
}


@media (max-width: 900px) {
  .qcm-answer-label{
    font-size: 12px!important;
  }
}
.theme-card {
  transition: all 0.1s ease !important;
  cursor: pointer !important;
}

.theme-card-image {
  transition: all 0.1s ease !important;
  cursor: pointer !important;
}

.theme-card:hover {
  box-shadow: rgb(44, 44, 44) 0px 0px 6px !important;
}

.button-with-arrow {
  cursor: pointer !important;
  transition: all 0.1s ease !important;
}

.button-with-arrow:hover {
  padding-right: 16px !important;
  background-color: var(--amplify-colors-brand-primary-60) !important;
}


.progress-bar {
  height: 8px;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.progress {
  height: 100%;
  background-color: var(--amplify-colors-brand-primary-60);
  border-radius: 5px;
}

.test-main {
  width: 100%;
  display: flex;
  justify-content: center;
}

.test-container {
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  margin: 0px 0px;
}

.test-header-container {
  width: 100%;
  padding: 0px 0px;
  font-size: 24px;
  font-weight: 600;
}

.test-content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  padding: 32px 32px;
}

.test-answers-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;

}

.test-image{
  width: 700px;
  height: 464.84px; 
  border-radius: 4px;
}

.test-menu{
  width:100%!important;
}


@media (max-width: 1200px) {
  .test-image{
    width: 100%;
    height: auto;
  }
}
@media (max-width: 1100px) {
  .test-content-container {
    width:100%;
    display: flex;
    flex-direction: column;
  }

  .test-image{
    width: 100%;
    height: auto;
  }

  .test-header{
    padding-top:32px!important;
  }

  .test-answers-container{
    gap:16px;
  }
}




.button-with-arrow {
  width: 100%;
}

.navbar-link {
  cursor: pointer !important;
  font-family: 'Futura', sans-serif !important;
}

.navbar-link.active {
  text-decoration: underline !important;
  text-decoration-color: var(--amplify-colors-brand-primary-60) !important;
  text-decoration-thickness: 3px !important;
  text-underline-offset: 1px !important;
}

.navbar-links {
  display: flex;
  justify-content: space-between;
}

.navbar-links.mobile {
  flex-direction: column;
  gap: 8px;
}

.navbar-link {
  margin-right: 32px;
}

.navbar-link:last-child {
  margin-right: 0;
}



.main-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.inner-container {
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin: 64px 0px;
}

.title-container {
  width: 100%;
  padding: 0px 32px;
  font-size: 24px;
  font-weight: 600;
  font-family: 'Futura';
}

.grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 32px;
  padding: 0px 32px;
}

.theme-grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 32px;
  padding: 0px 32px;
}

@media (max-width: 1200px) {
  .hero-navigo {
    display: flex;
    flex-direction: column-reverse !important;
    width: 100%;
    height: 100%;
  }

  .hero-left {
    margin: 0px;
    padding: 0px !important;
    width: 100% !important;
    max-width: 500px !important;
    height: auto !important;
  }

  .hero-right {
    margin: 0px;
    padding: 0px !important;
    height: 300px !important;
    width: auto !important;
  }
}

@media (max-width: 900px) {
  .inner-container {
    margin-top: 32px;
  }

  .grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 16px;
    padding: 0px 32px;
  }

  .theme-grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 16px;
    padding: 0px 32px;
  }

  .hero-line {
    display: flex;
    flex-direction: column !important;
  }

}


@media (max-width: 500px) {
  .grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 16px;
    padding: 0px 32px;
  }

  .theme-grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 16px;
    padding: 0px 32px;
  }

}

.amplify-button {
  font-family: 'Futura' !important;
  font-weight: 100 !important;
}




.mobile-navbar-menu {
  width: 100% !important;
}

@media (max-width:900px){
  .test-header {
    padding-left:16px!important;
    padding-right:16px!important;
  }
  .title-container {
    padding-left:16px!important;
    padding-right:16px!important;
  }
  .theme-grid-container {
    padding-left:16px!important;
    padding-right:16px!important;
  }
  .test-content-container{
    padding:16px!important;
    gap:16px!important;
  }

  .mobile-navbar {
    padding-left:16px!important;
    padding-right:16px!important;
  }

  .mobile-navbar-menu {
    padding-left:16px!important;
    padding-right:16px!important;
  }

  .grid-container {
    padding-left:16px!important;
    padding-right:16px!important;
  }

  .hero-2x2 {
    padding-left:16px!important;
    padding-right:16px!important;
  }

  .hero-navigo{
    padding-left:16px!important;
    padding-right:16px!important;
  }

  .inner-container{
    gap:16px!important;
  }
}
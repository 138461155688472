.card-primary:not(.disabled){
    cursor:pointer!important;
 
}

  .card-primary-button {
    transition: all 0.2s ease!important;
    background-color: var(--amplify-colors-brand-primary-60) !important;
}

.card-primary:not(.disabled):hover .card-primary-button {
    padding-right: 16px !important;
    background-color: black !important;
}


.card-secondary:not(.disabled){
    cursor:pointer!important;
  }

.card-secondary-button {
    transition: all 0.2s ease!important;
    background-color: var(--amplify-colors-brand-primary-60) !important;
}

.card-secondary:not(.disabled):hover .card-secondary-button {
    padding-right: 16px !important;
    background-color: black !important;
}

.card-secondary.disabled .card-secondary-button{
    background-color: #b1b5bc!important;
}
.card-secondary.disabled{
    box-shadow: none!important;
}
